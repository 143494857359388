<template>
  <div class="DetailContent">
    <div class="about" v-if="ArticleData.status == 1 && joinStudio">
      <el-button size="mini" type="primary" @click="PassWay(2)"
        >审核通过</el-button
      >
      <el-button size="mini" type="danger" @click="noPass"
        >审核不通过</el-button
      >
    </div>
    <div class="wzBox">
      <h4>{{ ArticleData.name }}</h4>
      <div class="mb32 wb">
        <div class="photo mr6" v-if="ArticleData.photo_url">
          <img :src="ArticleData.photo_url" alt="头像" />
        </div>
        <span v-if="ArticleData.username" class="mr50">{{
          ArticleData.username
        }}</span>
        <span class="mr50">发表于{{ ArticleData.start_date }}</span>
        <span class="mr50">{{ ArticleData.view_count }}阅读</span>
        <span class="mr50">{{ ArticleData.like_count }}点赞</span>
        <span class="mr50">{{ ArticleData.reply_count }}评论</span>
        <p class="inf_item_item" style="color: rgb(240, 95, 95)">
          {{
            ArticleData.status == 1
              ? "待审核"
              : ArticleData.status == 4
              ? "未通过"
              : ""
          }}
          <el-popover
            v-if="ArticleData.status == 4"
            placement="top-start"
            title=""
            width="200"
            trigger="hover"
            :content="ArticleData.reason"
          >
            <i
              style="color: rgb(240, 95, 95)"
              slot="reference"
              class="el-icon-question"
            ></i>
          </el-popover>
        </p>
      </div>

      <!-- 文章内容 -->
      <div
        class="typeface rich_text_div_img"
        v-html="ArticleData.content"
      ></div>
      <!-- 分享 -->
      <div class="pb12 shareBox">
        <!-- 左边分享 -->
        <div class="leftMain">
          <p>分享</p>
        </div>
        <!-- 右边点赞 -->
        <div class="rightMain">
          <div @click="tolike()">
            <i class="iconfont icondianzan mr5"></i>
            {{ ArticleData.like_count }}人点赞
          </div>
          <div>
            <i class="iconfont iconpinglun mr5"></i>
            {{ total }}条评论
          </div>
        </div>
      </div>
      <!-- 分享图片 -->
      <div class="df uu">
        <PublicShare></PublicShare>
      </div>
      <ul v-if="false" class="df uu">
        <li
          v-for="(e, i) in imgList"
          :key="i"
          @mouseenter="onMouseEnter(e, i)"
          @mouseleave="onMouseLeave(e)"
          @click="onShare"
          class="cursor"
        >
          <img :src="i == e.i ? e.img1 : e.img2" alt="" />
        </li>
      </ul>
    </div>
    <!-- //不通过弹框 -->
    <el-dialog title="不通过的理由" :visible.sync="dialogVisible" width="30%">
      <el-input
        type="textarea"
        :rows="5"
        placeholder="请输入审核不通过的理由"
        resize="none"
        v-model="form1.reason"
      >
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="PassWay(4)">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialogVisible: false,
      form1: {
        status: "", //2 审核通过 3审核不通过
        teaching_studio_id: localStorage.getItem("studioId"),
        id: "", //审核id
        reason: "",
      },
      joinStudio: false,
      ArticleData: "",
      list: {
        reply_id: "", //文章，回复或评论的id
        form: 1, //	1为文章 2为评论或回复
      },
      imgList: [
        {
          img1: require("@/assets/images/teacherStudio/wx_over.png"),
          img2: require("@/assets/images/teacherStudio/wx.png"),
          i: 0,
        },
        {
          img1: require("@/assets/images/teacherStudio/qq_over.png"),
          img2: require("@/assets/images/teacherStudio/qq.png"),
          i: 0,
        },
        {
          img1: require("@/assets/images/teacherStudio/xl_over.png"),
          img2: require("@/assets/images/teacherStudio/xl.png"),
          i: 0,
        },
        {
          img1: require("@/assets/images/teacherStudio/zone_over.png"),
          img2: require("@/assets/images/teacherStudio/zone.png"),
          i: 0,
        },
      ],
      current: 0,
    };
  },
  created() {
    //初始化
    this.joinStudio = JSON.parse(localStorage.getItem("joinStudio"));
    this.init();
  },
  computed: {},
  methods: {
    //不通过
    noPass() {
      this.form1.id = this.$route.query.tid; //审核id
      this.dialogVisible = true;
    },
    //通过
    PassWay(type) {
      this.form1.status = type; //2 审核通过 3审核不通过
      this.form1.id = this.$route.query.tid; //审核id
      this.$axios.put("teachingStudioArticle/status", this.form1).then(() => {
        if (type == 2) {
          this.$message.success("审核通过");
        } else {
          this.$message.success("审核不通过");
          this.dialogVisible = false;
        }
        this.$router.go(0);
      });
    },
    init() {
      //  初始化文章详情
      this.TxtDetail();
    },
    onMouseEnter(e, i) {
      e.i = i;
    },
    onMouseLeave(e) {
      e.i = 0;
    },
    // 分享
    onShare() {
      this.$message.warning("正在开发中...");
    },
    //初始化文章详情
    TxtDetail() {
      let id = this.$route.query.tid;
      this.$axios
        .get("teachingStudioArticle/details", {
          params: {
            id,
          },
        })
        .then((res) => {
          this.ArticleData = res.data.data;
        });
    },
    //点赞
    tolike() {
      this.list.reply_id = this.$route.query.tid; //文章id
      this.$axios
        .post("teachingStudioArticleReply/insertLike", this.list)
        .then(() => {
          this.$message.success("点赞成功");
          //  初始化文章详情
          this.TxtDetail();
        });
    },
  },
};
</script>
<style lang="less" scoped>
.DetailContent {
  background: #fff;
  box-sizing: border-box;
  position: relative;

  .about {
    position: absolute;
    right: -27px;
    top: -37px;
    background: #f00;
    padding: 10px;
    background: rgba(234, 241, 247, 1);
    border-radius: 0 8px 0 8px;
  }

  .wzBox {
    h4 {
      width: 959px;
      font-size: 24px;
      font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
      font-weight: 700;
      text-align: left;
      color: #333333;
      margin-bottom: 28px;
    }

    .wb {
      font-size: 16px;
      color: #333333;
      display: flex;
      align-items: center;
      border-bottom: 1px dashed #f7f7f7;
      padding-bottom: 27px;

      //图片
      .photo {
        width: 28px;
        height: 28px;

        img {
          width: 28px;
          height: 28px;
          border-radius: 50%;
        }
      }
    }

    .typeface {
      font-size: 16px;
      text-align: left;
      color: #333333;
      padding-bottom: 32px;
    }

    .shareBox {
      display: flex;
      justify-content: space-between;

      .leftMain {
        p {
          width: 32px;
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
          font-weight: 400;
          text-align: left;
          color: #9a9ea5;
        }

        .imgBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      //右边点赞
      .rightMain {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        div {
          display: flex;
          align-items: center;
          margin-left: 40px;
          font-size: 14px;
          font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
          font-weight: 400;
          text-align: left;
          color: #9a9ea5;

          img {
            width: 13px;
            height: 14px;
            background: #9a9ea5;
          }
        }
      }
    }

    .uu {
      padding-bottom: 30px;

      li {
        margin-right: 18px;
      }
    }
  }
}
</style>

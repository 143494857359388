<template>
  <div class="ArticleDetail public_main_bgc">
    <!-- 文章详情 -->
    <div class="ArrBox w_d">
      <!-- 导航 -->
      <div class="title">
        <p>
          当前位置：四名工作室 >
          <span @click="toList()" class="onL">教师文章</span> >
          <span>文章详情</span>
        </p>
      </div>
      <div class="fs discuss">
        <div class="mr20 bgc_f left">
          <titleCom :total="total" ref="titleCom" />
          <p class="send" v-if="isToken">发表评论</p>
          <!-- 发布盒子 -->
          <div class="pushBox" v-if="isToken">
            <div class="outBox">
              <el-input type="textarea" :rows="6" resize="none" placeholder="写下你的评论.." v-model="form.content" class="inputText">
              </el-input>
              <div class="addOneData cursor" @click="addComment()">发布</div>
            </div>
          </div>
          <new-comment :activity_id="form.teaching_studio_article_id" :module_type="7" ref="commentRef" @onChangeTotal="onChangeTotal"></new-comment>
        </div>
        <div class="right">
          <!-- 四名推荐文章 -->
          <div class="bgc_f common mb20">
            <p class="fw600 pb24">四名推荐文章</p>
            <ul class="uu" v-if="recommanderList.length > 0">
              <li class="cp" v-for="(e, i) in recommanderList" :key="i">
                <div class="tt" @click="gother(e.id)">
                  {{ e.name }}
                </div>
              </li>
            </ul>
            <CommonEmpty v-else />
          </div>
          <!-- 热门文章 -->
          <div class="bgc_f common">
            <p class="fw600 pb24">热门文章</p>
            <ul class="uu" v-if="hotList.length > 0">
              <li v-for="(e, i) in hotList" :key="i" class="hot_li cp">
                <div class="df" @click="gother(e.id)">
                  <div class="zfx" :style="{ background: color[i] || '#C0C4CC' }">
                    {{ i + 1 }}
                  </div>
                  <p class="tt" style="flex: 1">
                    {{ e.name }}
                  </p>
                </div>
              </li>
            </ul>
            <CommonEmpty v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleCom from "@/views/MasterStudio/TeachArticle/components/DetailContent.vue";
export default {
  data() {
    return {
      isToken: false,
      total: 0,
      color: {
        0: "#FA8C15",
        1: "#3489FF",
        2: "#00C1AE",
      },
      form: {
        teaching_studio_article_id: this.tid,
        user_id: localStorage.getItem("id"),
        content: "",
      },
      recommanderList: [],
      hotList: [],
    };
  },
  components: {
    titleCom,
  },
  methods: {
    gother(id) {
      this.$router.push({
        path: "/ArticleDetail",
        query: { tid: id },
      });
      this.$refs.titleCom.init();
    },
    //跳转详情
    toList() {
      this.$router.push("/ArticleIndex");
    },

    onChangeTotal(total) {
      this.total = total;
    },
    //添加评论
    async addComment() {
      let res = await this.$axios.post(
        "teachingStudioArticleReply/insert_comment",
        this.form
      );
      if (res.status == 200) {
        this.form.content = "";
        this.$message.success("评论成功");
        this.$refs.commentRef.getCommentList();
      }
    },
  },
  created() {
    this.isToken = localStorage.getItem("token") == null ? false : true;
    this.form.teaching_studio_article_id = this.$route.query.tid;
    const teaching_studio_id = this.$route.query.studioId
      ? this.$route.query.studioId
      : localStorage.getItem("studioId");
    // 四名推荐文章
    this.$axios
      .get("TeachingStudioArticle/status_listTeachingStudioArtic", {
        params: {
          limit: 6,
          page: 1,
          teaching_studio_id: teaching_studio_id,
        },
      })
      .then((res) => {
        this.recommanderList = res.data.data.data;
      });
    // 热门文章
    this.$axios
      .get("TeachingStudioArticle/hot_listTeachingStudioArtic", {
        params: {
          limit: 8,
          page: 1,
          teaching_studio_id: teaching_studio_id,
        },
      })
      .then((res) => {
        this.hotList = res.data.data.data;
      });
  },
};
</script>

<style lang="less" scoped>
.ArticleDetail {
  padding-bottom: 10px;
  .ArrBox {
    margin: 0 auto;
    .title {
      // width: 232px;
      height: 48px;
      font-size: 14px;
      font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
      font-weight: 400;
      text-align: left;
      color: #999999;
      display: flex;
      align-items: center;
      p {
        span:nth-last-child {
          color: #ff8201;
        }

        .onL {
          cursor: pointer;
          &:hover {
            color: #3489ff;
          }
        }
      }
    }
    .discuss {
      .left {
        width: 1045px;
        border-radius: 6px;
        padding: 40px 30px;
        .send {
          font-size: 18px;
          font-weight: 600;
          color: #1a1a1a;
          padding-bottom: 20px;
        }
        //发布盒子
        .pushBox {
          width: 975px;
          height: 184px;
          border: 1px solid #ececec;
          border-radius: 4px;
          margin-bottom: 40px;
          //内发布盒子
          .outBox {
            width: 100%;
            background: #fff;
            position: relative;
            .inputText {
              /deep/ .el-textarea__inner {
                border: none;
              }
            }
            .addOneData {
              position: absolute;
              left: 865px;
              top: 134px;
              margin-bottom: 10px;
              width: 92px;
              height: 34px;
              line-height: 34px;
              background: #fa8c15;
              border-radius: 4px;
              font-size: 16px;
              font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
              font-weight: 700;
              text-align: center;
              color: #ffffff;
            }
          }
        }
      }
      .right {
        flex: 1;
        .common {
          padding: 32px 24px;
          border-radius: 6px;
          .uu {
            border-radius: 6px;
            li {
              padding-bottom: 17px;
              margin-bottom: 17px;
              border-bottom: 1px solid #ebebeb;
              &:last-child {
                padding-bottom: 0px;
                margin-bottom: 0px;
                border-bottom: none;
              }
              &:hover {
                .tt {
                  color: #3489ff;
                }
              }
              .tt {
                line-height: 26px;
              }
              .zfx {
                width: 24px;
                height: 24px;
                line-height: 24px;
                // background: #fa8c15;
                border-radius: 2px;
                font-size: 14px;
                color: #fefefe;
                text-align: center;
                margin-right: 12px;
                margin-top: 5px;
              }
            }
            .hot_li {
              border-bottom: 1px dashed #ebebeb;
            }
          }
        }
      }
    }
  }
}
</style>
